import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { Modal } from 'src/components/Modal'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import ContentHeader from './sections/ContentHeader/_ContentHeader'
import OMelhorDoBanco from './sections/OMelhorDoBanco/_OMelhorDoBanco'
import ProdutosCompletos from './sections/ProdutosCompletos/_ProdutosCompletos'
import ComeceAInvestirJa from './sections/ComeceAInvestirJa/_ComeceAInvestirJa'
import ParaVoceInvestirMelhor from './sections/ParaVoceInvestirMelhor/_ParaVoceInvestirMelhor'
import VocePorDentro from './sections/VocePorDentro/_VocePorDentro'
import LiberdadeParaEscolher from './sections/LiberdadeParaEscolher/_LiberdadeParaEscolher'
import LimiteOperacional from './sections/LimiteOperacional/_LimiteOperacional'
import OQueE from './sections/OQueE/_OQueE'
import Blog from './sections/Blog/_Blog'
import MantenhaSeInformado from './sections/MantenhaSeInformado/_MantenhaSeInformado'
import FAQ from './sections/FAQ/_FAQ'
import DisclaimerInterInvest from 'src/components/DisclaimerInterInvest'

import pageContext from './pageContext.json'
import { Wrapper } from './style'
import qrcodeInvestimentsData from 'src/pages/pra-voce/investimentos/assets/images/qrcode/investimentos/_index'

const { qrCodeContaKidsAberturaDeContasInvestimentos, qrCodeAberturaDeContaRendaVariavel } = qrcodeInvestimentsData

const RendaVariavel = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)

  const dataLayer: IDataLayerParams = {
    section: 'dobra_13',
    section_name: 'Disclaimer',
    element_action: 'click button',
    element_name: '',
  }

  const openAccountRightForm = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <OpenAccountRightForm
        urlContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.deeplink}
        qrCodeContaKids={qrCodeContaKidsAberturaDeContasInvestimentos.qrcode}
        closeModal={() => setOpenModal(false)}
        formName='Formulario abertura de contas'
        dataLayer={dataLayer}
        qrBaixeApp={qrCodeAberturaDeContaRendaVariavel.qrcode}
        asFilledLink={qrCodeAberturaDeContaRendaVariavel.deeplink}
      />
    </Modal>
  )

  const handleClick = () => {
    setOpenModal(!openModal)
  }

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {openAccountRightForm}
        <ContentHeader handleClick={handleClick} sendDatalayerEvent={sendDatalayerEvent} />
        <OMelhorDoBanco sendDatalayerEvent={sendDatalayerEvent} />
        <ProdutosCompletos sendDatalayerEvent={sendDatalayerEvent} />
        <ComeceAInvestirJa sendDatalayerEvent={sendDatalayerEvent} />
        <ParaVoceInvestirMelhor handleClick={handleClick} sendDatalayerEvent={sendDatalayerEvent} />
        <VocePorDentro sendDatalayerEvent={sendDatalayerEvent} />
        <LiberdadeParaEscolher sendDatalayerEvent={sendDatalayerEvent} />
        <LimiteOperacional />
        <OQueE sendDatalayerEvent={sendDatalayerEvent} />
        <Blog sendDatalayerEvent={sendDatalayerEvent} />
        <MantenhaSeInformado sendDatalayerEvent={sendDatalayerEvent} />
        <FAQ data={pageContext.structuredData.faq} />
        <DisclaimerInterInvest
          sendDatalayerEvent={sendDatalayerEvent}
          dataLayer={dataLayer}
          section='dobra_13'
        />
      </Layout>
    </Wrapper>
  )
}

export default RendaVariavel
